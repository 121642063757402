import React from "react";
import { motion } from "framer-motion";

import "./Services.scss";
import { images } from "../../constants";

// const commercialServices = [
//   { title: "Janitorial", description: "Something about the job." },
//   {
//     title: "Window and Wall",
//     description: "Something about the job. We clean hospitals",
//   },
//   { title: "Offices", description: "Something about the job." },
//   {
//     title: "Ware Houses",
//     description: "Something about the job. We clean ministries",
//   },
//   {
//     title: "Floor Maintanance",
//     description: "Something about the job. We clean ministries",
//   },
//   {
//     title: "Pest Control",
//     description: "Something about the job. We clean ministries",
//   },
// ];

// const residentialServices = [
//   {
//     title: "Home Cleaning",
//     description: "Something about the job. We clean ministries",
//   },
//   {
//     title: "Carpet and Sofa Cleaning",
//     description: "Something about the job. We clean ministries",
//   },
//   {
//     title: "Sanitization and Disinfection",
//     description: "Something about the job. We clean ministries",
//   },
//   {
//     title: "Before and After Party",
//     description: "Something about the job. We clean ministries",
//   },
//   {
//     title: "Swiming Pool Cleaning",
//     description: "Something about the job. We clean ministries",
//   },
//   {
//     title: "Marbles Restoration",
//     description: "Something about the job. We clean ministries",
//   },
// ];

const Services = () => {
  return (
    <div id="services" className="service-container">
      <div className="motto-services">
        <h2 className="motto-text-services">
          Exxon rely on eco-friendly chemicals and other major hygiene
          products with environment protected MSDS !
        </h2>
      </div>
      <div className="service-main-container">
        <h2 className="head-text-service">Our Services</h2>
        <div className="underline-1"></div>
        <div className="underline-2"></div>
        <p className="service-def" style={{ marginTop: 10 }}>
          We are equipped with most advanced tools and ecofriendly cleaning
          liquids to stay up to any challenges. Among all Housekeeping companies
          in Bahrain, Exxon is definitely the most advanced and efficient in
          getting its tasks done on time by our professional team of experts and with
          unique work schedule. <br /> <br /> Our major highlights are{" "}
          <span style={{ fontWeight: "bold" }}>
            General Cleaning Services, Contract Cleaning Services, Industrial
            Cleaning Services, High-rise Building Cleaning (Rope Access
            Technology), Pest Control Services, Combined Facility Support
            Services, Sofa/Carpet Shampooing, Maintenance and Other General
            Support Services, Vinyl Floor Polishing, Management Support
            Services.
          </span>
        </p>
      </div>
      <div className="heading-services-sub ">
        <div className="main-item-services-1">
          <div>
            <div className="main-services">
              <h2 className="heading">Commercial Services</h2>
              <div className="underline-1"></div>
              <div className="underline-2"></div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Janitorial</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">
                  External Window and Wall Cleaning
                </p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Offices</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Warehouses</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Floor Maintenance</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Pest Control</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Seasonal Cleaning</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Post Construction Cleaning</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Combined Facility Support</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Industrial Cleaning Services</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Management Support Services</p>
              </div>
            </div>
          </div>
        </div>
        <div className="main-item-services-2">
          <div>
            <div className="main-services">
              <h2 className="heading">Residential Services</h2>
              <div className="underline-1"></div>
              <div className="underline-2"></div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Home Cleaning</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Carpet/Sofa Shampooing</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Sanitization and Disinfection</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Before and After Party</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Swimming Pool Cleaning</p>
              </div>
              <div className="services-item-container">
                <img
                  src={images.arrow}
                  className="service-us-arrow"
                  alt="vector_image"
                />
                <p className="service-item">Marble Polishing and Restoration</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
