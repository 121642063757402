import React from "react";
import { motion } from "framer-motion";

import "./Header.scss";

const Header = () => (
  <div id="home" className="app__header app__flex">
    <motion.div
      whileInView={{ x: [200, 0], opacity: [0, 1] }}
      transition={{ duration: 1 }}
      className="app__header-info"
    >
      <div className="app__header-badge">
        <div className="badge-cmp">
          <div style={{ marginLeft: 0 }}>
            <div>
              <h1 className="head-text-title">EXXON FACILITIES CO. W.L.L</h1>
            </div>
            <p className="p-text-subtitle">~ Total Facility Supporters ~</p>
            <p
              className="about-header"
              style={{ color: "#ffffff", marginTop: 30 }}
            >
              Exxon Facilities CO. W.L.L provides reliable, top-quality
              cleaning and combined facility support activities throughout The
              Kingdom of Bahrain since 2020. With a well-experienced team, our
              company and crew are dependable, professional, friendly, and
              completely trustworthy.
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  </div>
);

export default Header;
