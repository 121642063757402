import React from "react";
import { motion } from "framer-motion";

import { images } from "../../constants";
import "./Footer.scss";

const Footer = () => {
  return (
    <div id="contact" className="footer-container">
      <div className="heading-item">
        <h2 className="heading">Get in Touch With Us</h2>
        <div className="underline-1"></div>
        <div className="underline-2"></div>
      </div>
      <div className="app__footer-container">
        <div className="app__footer">
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.01 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__footer-item-1"
          >
            
            <a
              href="https://goo.gl/maps/BWQQLA6hG4k8SMxv6"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={images.location} alt="location" />
            </a>
            <p className="location-text" style={{ color: "#ffffff" }}>
              Office No. 105, Bldg No. B0540, Road No. 412, Block No. 704
            </p>
            <p className="location-text" style={{ color: "#ffffff" }}>
            Salmabad, Kingdom of Bahrain
            </p>
          </motion.div>
        </div>
        <div className="app__footer-cards">
          <div className="app__footer-card ">
            <img src={images.email} alt="email" />
            <a
              href="mailto:sales@exxonfacilities.net"
              className="p-text link-text"
            >
              sales@exxonfacilities.net
            </a>
          </div>
          <div className="app__footer-card">
            <img src={images.mobile} alt="mobile" />
            <a href="tel:+973 3218 3200" className="p-text">
              +973 3218 3200
            </a>
          </div>
          <div className="app__footer-card">
            <img src={images.mobile} alt="mobile" />
            <a href="tel:+973 3828 7840" className="p-text">
              +973 3828 7840
            </a>
          </div>
          <div className="app__footer-card">
            <img src={images.mobile} alt="mobile" />
            <a href="tel:+973 3939 7556" className="p-text">
              +973 3939 7556
            </a>
          </div>
          <div className="app__footer-card">
            <img src={images.phone} alt="phone" />
            <a href="tel:+973 1749 1781" className="p-text">
              +973 1749 1781
            </a>
          </div>
          <div className="app__footer-card">
            <img src={images.instagram} alt="instagram" />
            <a
              href="https://www.instagram.com/exxonfacilitiesco/"
              target="_blank"
              rel="noreferrer noopener"
              className="p-text"
            >
              exxonfacilitiesco
            </a>
          </div>
          <div className="app__footer-card">
            <img src={images.facebook} alt="facebook" />
            <a
              href="https://www.facebook.com/Exxonfacilitiesco"
              target="_blank"
              rel="noreferrer noopener"
              className="p-text"
            >
              exxonfacilities
            </a>
          </div>
        </div>
      </div>
      <div className="copy">
        <p className="link-text-1">@2022 EXXON FACILITIES CO. W.L.L</p>
        <p className="link-text-2">All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;

// 38287840
// 39397556
