
import logo from "../assets/logo.png"
import background from "../assets/background.png"
import homeImage from "../assets/homeImage.png"
import aboutImage from "../assets/aboutImage.png"
import location from "../assets/location.png"
import mobile from "../assets/mobile.png"
import phone from "../assets/phone.png"
import email from "../assets/email.png"
import instagram from "../assets/instagram.png"
import facebook from "../assets/facebook.png"
import arrow from "../assets/arrow.png"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    logo,
    background,
    homeImage,
    aboutImage,
    location,
    mobile,
    phone,
    email,
    instagram,
    facebook,
    arrow
}