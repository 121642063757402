import React, { useState } from "react";
import { motion } from "framer-motion";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player/youtube";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "./About.scss";
import { images, workImages } from "../../constants";

const abouts = [
  { title: "Commercial Offices", description: "Something about the job." },
  {
    title: "Hospitals",
    description: "Something about the job. We clean hospitals",
  },
  { title: "Education Institutions", description: "Something about the job." },
  {
    title: "Ministries",
    description: "Something about the job. We clean ministries",
  },
  { title: "Malls, Factories", description: "Something about the job." },
  { title: "Residential Homes", description: "Something about the job." },
];

const image = [
  {
    original: workImages.imageEleven,
    thumbnail: workImages.imageEleven,
  },
  {
    original: workImages.imageFive,
    thumbnail: workImages.imageFive,
  },
  {
    original: workImages.imageTwentyThree,
    thumbnail: workImages.imageTwentyThree,
  },
  {
    original: workImages.imageTwentyFour,
    thumbnail: workImages.imageTwentyFour,
  },
  {
    original: workImages.imageTwentyFive,
    thumbnail: workImages.imageTwentyFive,
  },
  {
    original: workImages.imageTwentySix,
    thumbnail: workImages.imageTwentySix,
  },
  {
    original: workImages.imageTwentySeven,
    thumbnail: workImages.imageTwentySeven,
  },
  {
    original: workImages.imageTwo,
    thumbnail: workImages.imageTwo,
  },
  {
    original: workImages.imageThree,
    thumbnail: workImages.imageThree,
  },
  {
    original: workImages.imageFour,
    thumbnail: workImages.imageFour,
  },

  {
    original: workImages.imageSix,
    thumbnail: workImages.imageSix,
  },
  {
    original: workImages.imageTwelve,
    thumbnail: workImages.imageTwelve,
  },
  {
    original: workImages.imageThirteen,
    thumbnail: workImages.imageThirteen,
  },
  {
    original: workImages.imageFourteen,
    thumbnail: workImages.imageFourteen,
  },
  {
    original: workImages.imageFifteen,
    thumbnail: workImages.imageFifteen,
  },
  {
    original: workImages.imageEighteen,
    thumbnail: workImages.imageEighteen,
  },
  {
    original: workImages.imageNineteen,
    thumbnail: workImages.imageNineteen,
  },
  {
    original: workImages.imageTwentyOne,
    thumbnail: workImages.imageTwentyOne,
  },
  {
    original: workImages.imageTwentyTwo,
    thumbnail: workImages.imageTwentyTwo,
  },
];

const About = () => {
  const [showImage, setShowImage] = useState(true);
  const [showVideo, setShowVideo] = useState(false);

  // const onClickImages = () => {
  //   setShowImage(true)
  // }
  // const onClickVideos = () => {
  //   setShowVideo(true)
  // }

  const onClickImages = () => {
    setShowImage(true);
    setShowVideo(false);
  };
  const onClickVideos = () => {
    setShowVideo(true);
    setShowImage(false);
  };

  return (
    <div id="about" className="about-container">
      <div className="motto-about">
        <h2 className="motto-text">For us CLEANLINESS matters the most !!!</h2>
      </div>
      <div className="mobile-only">
        <h2 className="heading-mobile-only">Some Facts About Us</h2>
        <div className="underline-1"></div>
        <div className="underline-2"></div>
        <p className="about-us-mobile-only" style={{ marginTop: 10 }}>
          Exxon is a professionally managed organization equipped to explore new
          avenues in the housekeeping industry with modern technology and
          state-of-the-art equipment. Being a qualified player in the field of
          Combined Facility Support activities, the scope and importance of
          Exxon is already spread across the region. Health and hygiene have
          become a master thought of the world and the importance of the hygiene
          industry is widened with unlimited scope.
          <br /> <br /> With a well-experienced team, our company and crew are
          dependable, professional, friendly and trustworthy. We take good care
          of your offices, homes, flats, and other buildings and clean them as
          our own. We stand behind our performance and guarantee customer
          satisfaction. We care about our services to you and, if you are not
          satisfied, we are not.
        </p>
      </div>
      <div className="head-text-subheading-about-us">
        <div className="web-only">
          <h2 className="heading-web-only">Some Facts About Us</h2>
          <div className="underline-1"></div>
          <div className="underline-2"></div>
          <p className="about-us-web-only" style={{ marginTop: 10 }}>
            Exxon is a professionally managed organization equipped to explore
            new avenues in the housekeeping industry with modern technology and
            state-of-the-art equipment. Being a qualified player in the field of
            Combined Facility Support activities, the scope and importance of
            Exxon is already spread across the region. Health and hygiene have
            become a master thought of the world and the importance of the
            hygiene industry is widened with unlimited scope.
            <br /> <br /> With a well-experienced team, our company and crew are
            dependable, professional, friendly and trustworthy. We take good
            care of your offices, homes, flats, and other buildings and clean
            them as our own. We stand behind our performance and guarantee
            customer satisfaction. We care about our services to you and, if you
            are not satisfied, we are not.
          </p>
        </div>
        {/* <div className="item-2-about">
          <h2 className="head-text">Some Facts About Us</h2>
          <div className="underline-1"></div>
          <div className="underline-2"></div>
          <p className="about-us" style={{ marginTop: 10 }}>
            Exxon is a professionally managed organization equipped to explore
            new avenues in the housekeeping industry with modern technology and
            state-of-the-art equipment. Being a qualified player in the field of
            Combined Facility Support activities, the scope and importance of
            Exxon is already spread across the region. Health and hygiene have
            become a master thought of the world and the importance of the
            hygiene industry is widened with unlimited scope.
            <br /> <br /> With a well-experienced team, our company and crew are
            dependable, professional, friendly and trustworthy. We take good
            care of your office, home, flat, and other buildings and clean them
            as our own. We stand behind our performance and guarantee customer
            satisfaction. We care about our services to you and, if you are not
            satisfied, we are not.
          </p>
        </div> */}
        <div className="main-item">
          <div>
            <div className="main-item-about">
              <diV>
                <h2 className="heading">Our Areas of Expertise</h2>
                <div className="underline-1"></div>
                <div className="underline-2"></div>
              </diV>
              <div className="about-us-item-container">
                <img
                  src={images.arrow}
                  className="about-us-arrow"
                  alt="vector_image"
                />
                <p className="about-us-item">Commercial Offices</p>
              </div>
              <div className="about-us-item-container">
                <img
                  src={images.arrow}
                  className="about-us-arrow"
                  alt="vector_image"
                />
                <p className="about-us-item">Hospitals</p>
              </div>
              <div className="about-us-item-container">
                <img
                  src={images.arrow}
                  className="about-us-arrow"
                  alt="vector_image"
                />
                <p className="about-us-item">Educational Institutions</p>
              </div>
              <div className="about-us-item-container">
                <img
                  src={images.arrow}
                  className="about-us-arrow"
                  alt="vector_image"
                />
                <p className="about-us-item">Ministries</p>
              </div>
              <div className="about-us-item-container">
                <img
                  src={images.arrow}
                  className="about-us-arrow"
                  alt="vector_image"
                />
                <p className="about-us-item">Hotels and Restaurants</p>
              </div>
              <div className="about-us-item-container">
                <img
                  src={images.arrow}
                  className="about-us-arrow"
                  alt="vector_image"
                />
                <p className="about-us-item">Malls and Factories</p>
              </div>
              <div className="about-us-item-container">
                <img
                  src={images.arrow}
                  className="about-us-arrow"
                  alt="vector_image"
                />
                <p className="about-us-item">Residential Homes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="image-video-container">
        <div className="main-item-2">
          <h2 className="heading-recent-works">Our Scope</h2>
          <div className="underline-3"></div>
          <div className="underline-4"></div>
        </div>
        <div>
          <div className="app__profiles">
            <button className="app__profile-button" onClick={onClickImages}>
              Images
            </button>

            <button className="app__profile-button" onClick={onClickVideos}>
              Videos
            </button>
          </div>
        </div>
        {showImage && !showVideo && (
          <div className="our-works-image">
            <ImageGallery
              items={image}
              originalHeight={20}
              thumbnailPosition={"bottom"}
            />
          </div>
        )}
        {showVideo && !showImage && (
          <div className="our-works-videos">
            <Carousel
              className="video-container"
              centerMode={true}
              dynamicHeight={true}
              emulateTouch={true}
              showIndicators={false}
            >
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/qcrSNlf-tWg?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/_yOlrRJRyds?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/EyTHrgMhzeE?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/9GFNzfGa8-0?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/Kj5H5URUOxg?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/g742bst_Bpg?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/qANPtmee9wQ?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtu.be/ibmJlmOCuyI"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/johY1DMAZoI?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/KgMS9oTFmPA?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/p4Hwzftc_V0?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtu.be/Fs3mMMg_gaY"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/TjjMXHHRvDk?feature=share"
                controls={true}
              />
              <ReactPlayer
                className="video"
                height={500}
                width={"100%"}
                url="https://youtube.com/shorts/bu22rGsBlOg?feature=share"
                controls={true}
              />
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;

// https://youtube.com/shorts/qcrSNlf-tWg?feature=share
// https://youtube.com/shorts/_yOlrRJRyds?feature=share
