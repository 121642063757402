import imageOne from "../assets/workImages/Image 1.jpeg";
import imageTwo from "../assets/workImages/Image 2.jpeg";
import imageThree from "../assets/workImages/Image 3.jpeg";
import imageFour from "../assets/workImages/Image 4.jpeg";
import imageFive from "../assets/workImages/Image 5.jpeg";
import imageSix from "../assets/workImages/Image 6.jpeg";
import imageSeven from "../assets/workImages/Image 7.jpeg";
import imageEight from "../assets/workImages/Image 8.jpeg";
import imageNine from "../assets/workImages/Image 9.jpeg";
import imageTen from "../assets/workImages/Image 10.jpeg";
import imageEleven from "../assets/workImages/Image 11.jpeg";
import imageTwelve from "../assets/workImages/Image 12.jpeg";
import imageThirteen from "../assets/workImages/Image 13.jpeg";
import imageFourteen from "../assets/workImages/Image 14.jpeg";
import imageFifteen from "../assets/workImages/Image 15.jpeg";
import imageSixteen from "../assets/workImages/Image 16.jpeg";
import imageSeventeen from "../assets/workImages/Image 17.jpeg";
import imageEighteen from "../assets/workImages/Image 18.jpeg";
import imageNineteen from "../assets/workImages/Image 19.jpeg";
import imageTwenty from "../assets/workImages/Image 20.jpeg";
import imageTwentyOne from "../assets/workImages/Image 21.jpeg";
import imageTwentyTwo from "../assets/workImages/Image 22.jpg";
import imageTwentyThree from "../assets/workImages/Image 23.jpg";
import imageTwentyFour from "../assets/workImages/Image 24.jpg";
import imageTwentyFive from "../assets/workImages/Image 25.jpg";
import imageTwentySix from "../assets/workImages/Image 26.jpg";
import imageTwentySeven from "../assets/workImages/Image 27.jpg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  imageOne,
  imageTwo,
  imageThree,
  imageFour,
  imageFive,
  imageSix,
  imageSeven,
  imageEight,
  imageNine,
  imageTen,
  imageEleven,
  imageTwelve,
  imageThirteen,
  imageFourteen,
  imageFifteen,
  imageSixteen,
  imageSeventeen,
  imageEighteen,
  imageNineteen,
  imageTwenty,
  imageTwentyOne,
  imageTwentyTwo,
  imageTwentyThree,
  imageTwentyFour,
  imageTwentyFive,
  imageTwentySix,
  imageTwentySeven,
};
